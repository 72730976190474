<template>
  <div class="cont-warp">
    <el-row>
      <a v-if="isRetail" :href="staticFile.merchandiseImportZdyLs.url"
         :download="staticFile.merchandiseImportZdyLs.name">{{staticFile.merchandiseImportZdyLs.name}}</a>
      <a v-else :href="staticFile.merchandiseImportZdyJd.url" :download="staticFile.merchandiseImportZdyJd.name">{{staticFile.merchandiseImportZdyJd.name}}</a>
    </el-row>
    <!-- <el-row>
      <a :href="staticFile.merchandiseImportJsp.url" :download="staticFile.merchandiseImportJsp.name">{{staticFile.merchandiseImportJsp.name}}</a>
    </el-row>
    <el-row>
      <a :href="staticFile.merchandiseImportSkp.url" :download="staticFile.merchandiseImportSkp.name">{{staticFile.merchandiseImportSkp.name}}</a>
    </el-row> -->
  </div>
</template>
<script>
import StaticFile from '@/assets/js/static-file'

export default {
  name: 'MerchandiseTemplateDownload',
  computed: {
    isRetail () {
      return localStorage.getItem('INDUSTRY') == 'RETAIL'
    }
  },
  data () {
    return {
      staticFile: StaticFile
    }
  }
}
</script>

<style scoped lang="scss">
  .cont-warp {
    margin-top: -30px;
    text-align: center;
    padding: 0px 30px 30px 30px;
  }

  a {
    color: #1890FF;
    font-size: 16px;
    font-weight: 400;
    line-height: 2em;
    text-decoration: none;
    font-family: PingFangSC-Regular, PingFang SC;
  }
</style>
