<template>
  <el-form
    class="income-wrap"
    ref="merchandiseFileRef"
    :rules="merchandiseFileRules"
    :model="merchandiseFile"
    v-loading="uploadLoading"
  >
    <div class="edit-select">
      <div class="edit-select-item">
        <el-form-item prop="orgId" label="组织">
          <organization-select
            :model="merchandiseFile"
            :is-add-all="false"
          ></organization-select>
        </el-form-item>
      </div>
      <div class="edit-select-item">
        <el-form-item prop="fileType" label="导入模板格式">
          <el-select v-model="merchandiseFile.fileType" placeholder="请选择">
            <el-option
              value="xls"
              label="系统自定义文件 xlsx 或 xls"
            ></el-option>
            <!-- <el-option value="txt" label="航信金税盘文件 txt"></el-option>
            <el-option value="xml" label="百旺税控盘文件 xml"></el-option> -->
          </el-select>
        </el-form-item>
      </div>
      <div class="edit-select-item">
        <el-input
          v-model="merchandiseFile.fileName"
          :placeholder="placeholder"
          style="width: 300px"
        ></el-input>
        <el-upload
          :accept="uploadType"
          :action="uploadUrl"
          :auto-upload="false"
          :multiple="false"
          :show-file-list="false"
          :on-exceed="handleExceed"
          :on-change="uploadFile"
        >
          <el-button type="default" icon="el-icon-upload2">
            上传文件
          </el-button>
        </el-upload>
      </div>
    </div>
    <div class="edit-message">
      注：请下载系统自定义导入模板编辑后上传
    </div>
    <div class="edit-select">
      <div class="edit-select-foot">
        <el-button class="el-button-ext" @click="handleCancel">取消</el-button>
        <el-button
          class="el-button-ext"
          @click="handleSaveGoods"
          :disabled="saving"
          type="primary"
          >导入
        </el-button>
      </div>
    </div>
    <el-dialog
      append-to-body
      title="提示"
      width="412px"
      :visible.sync="saving"
      :show-close="false"
    >
      <div class="dialog-content">
        <span>批量导入时相同商品编码的信息会覆盖，是否继续导入?</span>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button type="default" @click="cancelImport">取消</el-button>
        <el-button
          type="primary"
          @click="confirmImport"
          :disabled="buttonDisabled"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </el-form>
</template>

<script>
import OrganizationSelect from "@/components/widgets/OrganizationSelect";
import { uploadMerchandise } from "@/service/merchandise";
import { baseURL } from '@/service/index';

export default {
  name: "MerchandiseImport",
  components: { OrganizationSelect },
  data() {
    return {
      saving: false,
      merchandiseFile: {
        orgId: "",
        fileType: "",
        fileName: "",
        file: {},
      },
      merchandiseFileRules: {
        fileType: [{ required: true, message: "请选择导入模板格式" }],
      },
      uploadLoading: false,
      buttonDisabled: false,
      uploadUrl: `${baseURL}/merchandise/upload`
    };
  },
  computed: {
    placeholder() {
      if (
        "xlsx" == this.merchandiseFile.fileType ||
        "xls" == this.merchandiseFile.fileType
      ) {
        return "文件名称.xlsx 或 文件名称.xls";
      }
      if ("txt" == this.merchandiseFile.fileType) {
        return "文件名称.txt";
      }
      if ("xml" == this.merchandiseFile.fileType) {
        return "文件名称.xml";
      }
      return "";
    },
    uploadType() {
      let fileType = this.merchandiseFile.fileType;
      let restrictFileType = "";
      switch (fileType) {
        case "txt":
          restrictFileType = ".txt";
          break;
        case "xml":
          restrictFileType = ".xml";
          break;
        case "xls":
        case "xlsx":
          restrictFileType =
            "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          break;
        default:
          break;
      }
      return restrictFileType;
    },
  },
  methods: {
    /* 上传错误捕获 */
    handleExceed() {
      this.toast("上传文件出错", "error");
    },
    /* 导入保存 */
    async handleSaveGoods() {
      this.$refs.merchandiseFileRef.validate((valid) => {
        if (!valid) return;
      });
      if (!this.merchandiseFile.file.name) {
        this.toast("请选择要上传文件！", "warning");
        return;
      }
      if (!this.checkFileType()) {
        this.toast("上传的文件格式与税盘要求不符！", "warning");
        return;
      }
      this.saving = true;
    },
    /* 校验文件类型与税盘是否对应 */
    checkFileType() {
      if (this.merchandiseFile.file.name && this.merchandiseFile.file) {
        const fileNameType = this.merchandiseFile.file?.name.substring(
          this.merchandiseFile.file.name.length - 3
        );
        if (this.merchandiseFile.fileType == fileNameType) {
          return true;
        }
        const isXlsxExcel =
          this.merchandiseFile.file.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (isXlsxExcel) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
    uploadFile(file) {
      this.merchandiseFile.fileName = file.name;
      this.merchandiseFile.file = file.raw;
    },
    /* 重置表单 */
    resetForm() {
      this.$refs.merchandiseFileRef.resetFields();
      this.merchandiseFile.fileName = "";
      this.merchandiseFile.file = {};
    },
    /* 通知父组件关闭dialog */
    handleCancel() {
      this.resetForm();
      this.$emit("handleCancelImportDialog");
    },
    //处理取消
    cancelImport() {
      this.saving = false;
      this.resetForm();
      this.$emit("handleCancelImportDialog");
    },
    //处理确认
    async confirmImport() {
      let formData = new FormData();
      formData.append("file", this.merchandiseFile.file);
      formData.append("orgId", this.merchandiseFile.orgId);
      formData.append("fileType", this.merchandiseFile.fileType);

      this.buttonDisabled = true;
      this.uploadLoading = true;
      const res = await uploadMerchandise(formData);
      this.uploadLoading = false;
      this.buttonDisabled = false;
      if (res.success) {
        this.toast("商品导入成功", "success", () => this.handleCancel());
      }
      this.$emit("handReloadMerchandisList");
      this.saving = false;
    },
  },
};
</script>

<style scoped lang="scss">
.income-wrap {
  padding: 0 24px;
}

::v-deep .el-form-item__label {
  padding: 0;
  line-height: 24px;
}

::v-deep .el-form-item__error {
  top: 58px;
  padding-top: 2px;
}
::v-deep .dialog-footer {
  padding-top: 25px;
  text-align: center;
}

  ::v-deep .el-select, ::v-deep .el-input {
    width: 453px;
  }

.edit-select {
  .edit-select-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .edit-select-item:first-child {
    margin-bottom: 50px;
  }

  .edit-select-item:last-child {
    margin-top: 60px;
  }

  .edit-select-foot {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
  }
}

.edit-message {
  margin-top: 25px;
  margin-bottom: 32px;
  text-align: left;
  font-size: 14px;
  color: #f5222d;
}
.dialog-content {
  font-size: 16px;
  color: #333333;
}
.dialog-footer {
  text-align: right;
}
</style>
